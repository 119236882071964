import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "src/components/footer"
import Appbar from "src/components/appbar"
import "./index.css"
import { ThemeProvider } from "@material-ui/core"
import theme from "src/theme"

const Layout = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <ThemeProvider theme={theme}>
        <Appbar location={location} />
        <main>{children}</main>
        <Footer />
      </ThemeProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
