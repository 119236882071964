import React from "react"
import { withStyles } from "@material-ui/core/styles"
import appLogo from "../../images/dp_aerogels_logomark.png"
import { Link } from "gatsby"
import HamburgerMenu from "react-hamburger-menu"
import MobileNav from "./mobile"
import theme from "../../theme"

const styles = theme => ({
  root: {
    width: "100%",
    position: "absolute",
    display: "block",
    zIndex: 1,
  },
  headerContainer: {
    display: "block",
    position: "relative",
    height: "80px",
    background: "#fff",
    borderBottom: "2px solid #D0D0D0",
  },
  appLogoContainer: {
    position: "absolute",
    height: "100%",
    padding: "14px 28px",
    paddingLeft: "96px",
    "@media (max-width:768px)": {
      paddingLeft: "42px",
    },
  },
  appLogo: {
    height: "42px",
  },
  hamburgerContainer: {
    display: "none",
    "@media (max-width:768px)": {
      display: "block",
      position: "absolute",
      top: 0,
      right: 0,
      padding: "36px",
      zIndex: 3,
    },
  },
  navContainer: {
    display: "block",
    maxWidth: "1440px",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    float: "none",
    "@media (max-width:768px)": {
      display: "none",
    },
  },
  navGroupWrapper: {
    position: "relative",
    height: "80px",
    marginLeft: "320px",
    width: "85%",
  },
  navGroup: {
    display: "inline-block",
    margin: 0,
    padding: 0,
    listStyle: "none",
    lineHeight: "46px",
    height: "80px",
  },
  navItem: {
    position: "relative",
    float: "left",
    display: "block",
    top: 28,
    marginRight: "56px",
  },
  subNavGroup: {
    display: "inline-block",
    listStyle: "none",
    marginLeft: "54px",
  },
  subNavItem: {
    float: "left",
    display: "block",
    marginRight: "56px",
  },
  navText: {
    fontSize: "1rem",
    fontFamily: "DINPro",
    fontWeight: "500",
    lineHeight: "1.43",
    textDecoration: "none",
    color: theme.palette.primary.main,
    "&:hover, &:focus": {
      color: theme.palette.secondary.main,
      // "&:before": {
      //     content: 'url(../../images/brand_leaf_element.svg)',
      //     position: 'relative',
      //     top: '-12px',
      // }
    },
  },
  subNavContainer: {
    borderBottom: "1px solid #D0D0D0",
    height: "48px",
    width: "100%",
    background: "#fff",
    "@media (max-width:768px)": {
      display: "none",
    },
  }
})

class Appbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = { open: false }
  }

  handleClick() {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    const { classes, location } = this.props

    const shouldShowSubNav = location.pathname.indexOf("about") >= 0 || location.pathname.indexOf("products") >= 0

    return (
      <header className={classes.root}>
        <div className={classes.headerContainer}>
          <div className={classes.appLogoContainer}>
            <Link to="/">
              <img src={appLogo} className={classes.appLogo} />
            </Link>
          </div>
          <nav className={classes.navContainer}>
            <div className={classes.navGroupWrapper}>
              {renderNavLinks(classes)}
            </div>
          </nav>
          {shouldShowSubNav && (
            <nav className={classes.subNavContainer}>
              {renderSubNavLinks(location, classes)}
            </nav>
          )}
          <div className={classes.hamburgerContainer}>
            <HamburgerMenu
              isOpen={this.state.open}
              menuClicked={this.handleClick.bind(this)}
              width={24}
              height={16}
              strokeWidth={3}
              rotate={0}
              color={this.state.open ? "white" : "black"}
              borderRadius={16}
              animationDuration={0.4}
            />
          </div>
        </div>
        {this.state.open ? <MobileNav location={location} /> : null}
      </header>
    )
  }
}

const renderSubNavLinks = (location, classes) => {
  const { pathname } = location
  if (pathname.indexOf("about") >= 0) {
    return (
      <>
        <ul className={classes.subNavGroup}>
          {aboutNav.map((nav, index) => (
            <li key={index} className={classes.subNavItem}>
              <Link
                to={nav.link}
                className={classes.navText}
                activeStyle={{ color: theme.palette.secondary.main }}
              >
                {nav.heading}
              </Link>
            </li>
          ))}
        </ul>
      </>
    )
  } else if (pathname.indexOf("products") >= 0) {
    return (
      <>
        <ul className={classes.subNavGroup}>
          {productsNav.map((nav, index) => (
            <li key={index} className={classes.subNavItem}>
              <Link
                to={nav.link}
                className={classes.navText}
                activeStyle={{ color: theme.palette.secondary.main }}
              >
                {nav.heading}
              </Link>
            </li>
          ))}
        </ul>
      </>
    )
  }
}


const renderNavLinks = (classes) => {
  return (
    <>
      <ul className={classes.navGroup}>
        {mainNav.map((nav, index) => (
          <li key={index} className={classes.navItem}>
            <Link
              to={nav.link}
              className={classes.navText}
              activeStyle={{ color: theme.palette.secondary.main }}
            >
              {nav.heading}
            </Link>
          </li>
        ))}
      </ul>
    </>
  )
}

export const aboutNav = [
  {
    heading: "Who We Are",
    link: "/about/who-we-are",
  },
  {
    heading: "Sustainability",
    link: "/about/sustainability",
  },
  {
    heading: "Brand Identity",
    link: "/about/brand-identity",
  },
]

export const productsNav = [
  {
    heading: "Overview",
    link: "/products/overview",
  },
  {
    heading: "Serogel™",
    link: "/products/serogel",
  },
  {
    heading: "Derogel™",
    link: "/products/derogel",
  },
]

export const mainNav = [
  {
    heading: "Products",
    link: "/products/overview",
    items: productsNav
  },
  {
    heading: "About Us",
    link: "/about/who-we-are",
    items: aboutNav
  },
  {
    heading: "Contact",
    link: "/contact",
  },
]

export default withStyles(styles)(Appbar)
