import { createTheme } from "@material-ui/core/styles"

/**
 * We have 1 breakpoint to account for at 768px to cater to tablet screens.
 */
const breakpoint = "768px"
const primaryColor = "#2E5254" // DP Jungle Green
const primaryColorLight = "#D5DCDD"
const secondaryColor = "#99CC33" // DP Organic Green
const secondaryColorLight = "#EBF5D6"
const greyBackground = "#f0f0f0"

let theme = createTheme({
  typography: {
    fontFamily: [
      "DINPro", // <- The custom font first
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: primaryColor,
      light: primaryColorLight,
    },
    secondary: {
      main: secondaryColor,
      light: secondaryColorLight,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
    },
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
})

theme.typography.h1 = {
  color: primaryColor,
  fontFamily: "DINPro",
  fontSize: "2.85rem",
  overflowWrap: "break-word",
  display: "block",
  "@media (max-width:768px)": {
    fontSize: "2.225rem",
  },
}

theme.typography.h2 = {
  color: primaryColor,
  fontFamily: "DINPro",
  fontSize: "2.425rem",
  lineHeight: "1.2",
  overflowWrap: "break-word",
  display: "block",
  "@media (max-width:768px)": {
    fontSize: "2rem",
  },
}

theme.typography.h3 = {
  color: primaryColor,
  fontFamily: "DINPro",
  fontSize: "2rem",
  overflowWrap: "break-word",
  "@media (max-width:768px)": {
    fontSize: "1.75rem",
  },
}

theme.typography.h4 = {
  color: primaryColor,
  fontFamily: "DINPro",
  fontSize: "1.875rem",
  overflowWrap: "break-word",
  "@media (max-width:768px)": {
    fontSize: "1.75rem",
  },
}

theme.typography.h5 = {
  color: primaryColor,
  fontFamily: "DINPro",
  fontSize: "1.5rem",
  overflowWrap: "break-word",
  "@media (max-width:768px)": {
    fontSize: "1.375rem",
  },
}

theme.typography.h6 = {
  color: primaryColor,
  fontFamily: "DINPro",
  overflowWrap: "break-word",
  fontSize: "1.125rem",
}

theme.typography.subtitle1 = {
  fontFamily: "DINPro",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "1.657",
  overflowWrap: "break-word",
  "@media (max-width:768px)": {
    fontSize: "0.875rem",
  },
}

theme.typography.body1 = {
  fontSize: "1.15rem",
  fontFamily: "DINPro",
  lineHeight: "1.657",
  overflowWrap: "break-word",
  "@media (max-width:768px)": {
    fontSize: "1rem",
  },
}

theme.typography.body2 = {
  fontSize: "1.15rem",
  fontFamily: "DINPro",
  lineHeight: "1.657",
  overflowWrap: "break-word",
  "@media (max-width:768px)": {
    fontSize: "1rem",
  },
}

export default theme
