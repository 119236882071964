import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import appLogo from "../../images/dp_aerogels_logomark.png"
import { getCurrentYearString } from "../../utils/time"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100vw",
    paddingTop: "48px",
    backgroundColor: "#fff",
    borderTop: "1px solid #D0D0D0",
  },
  footerContainer: {
    maxWidth: "1230px",
    margin: "0 auto",
    width: "85%",
  },
  appLogoContainer: {
    display: "flex",
    paddingBottom: "32px",
  },
  appLogo: {
    height: "60px",
    alignSelf: "center",
  },
  navGroup: {
    alignSelf: "flex-end",
    listStyle: "none",
    margin: "0",
    padding: "0",
    marginBottom: "12px",
  },
  navItem: {
    display: "inline-block",
    marginRight: "24px",
  },
  navText: {
    fontSize: "0.875rem",
    fontFamily: "DINPro",
    fontWeight: "500",
    lineHeight: "1.43",
    textDecoration: "none",
    color: theme.palette.primary.main,
    "&:hover, &:focus": {
      color: theme.palette.secondary.main,
      // "&:before": {
      //   content: "url(../../images/brand_leaf_element.svg)",
      //   position: "relative",
      //   top: "-12px",
      // },
    },
  },
  bottomFooterInner: {
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: "1230px",
    paddingBottom: "24px",
    paddingTop: "24px",
    borderTop: "1px solid #D0D0D0",
  },
  copyright: {},
}))

export default function Footer() {
  const classes = useStyles()

  return (
    <footer className={classes.root}>
      <div className={classes.footerContainer}>
        <section>
          <div className={classes.appLogoContainer}>
            <img src={appLogo} className={classes.appLogo} />
          </div>
          <div>
            <ul className={classes.navGroup}>
              {footerNav.map((item, index) => (
                <li className={classes.navItem} key={index}>
                  <Link to={item.link} className={classes.navText}>
                    {item.heading}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <div className={classes.bottomFooterInner}>
          <Typography className={classes.copyright} variant="subtitle1">
            © {getCurrentYearString()} DP Aerogels International
          </Typography>
        </div>
      </div>
    </footer>
  )
}

export const footerNav = [
  {
    heading: "Browse Products",
    link: "/products/overview",
  },
  {
    heading: "Serogel™",
    link: "/products/serogel",
  },
  {
    heading: "Derogel™",
    link: "/products/derogel",
  },
  {
    heading: "About Us",
    link: "/about/who-we-are",
  },
  {
    heading: "Sustainability",
    link: "/about/sustainability",
  },
  {
    heading: "Brand Identity",
    link: "/about/brand-identity",
  },
  {
    heading: "Contact Us",
    link: "/contact",
  },
  {
    heading: "Terms of Use",
    link: "/terms-of-use",
  },
  {
    heading: "Privacy Policy",
    link: "/privacy-policy",
  },
]
