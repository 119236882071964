import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import theme from "../../../theme"
import { mainNav, aboutNav, productsNav } from "../index"
import darkAppLogo from "../../../images/dp_aerogels_logomark_dark.png"

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.main,
    height: "100vh",
    width: "100vw",
    position: "fixed",
    left: 0,
    top: 0,
  },
  headerContainer: {
    display: "block",
    height: "80px",
    background: theme.palette.primary.main,
    borderBottom: "2px solid #D0D0D0",
  },
  appLogoContainer: {
    position: "absolute",
    padding: "14px 28px",
    paddingLeft: "96px",
    "@media (max-width:768px)": {
      paddingLeft: "42px",
    },
  },
  appLogo: {
    height: "42px",
  },
  navContainer: {
    marginTop: "24px",
  },
  navItemHeader: {
    lineHeight: "46px",
    fontSize: "1rem",
    fontFamily: "DINPro",
    fontWeight: "700",
    color: "#fff",
    borderBottom: "1px solid #D0D0D0",
    padding: "4px",
  },
  navGroup: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    lineHeight: "46px",
    marginLeft: "10%",
    width: "50%",
    marginBottom: "1rem",
  },
  navText: {
    fontSize: "1.125rem",
    fontFamily: "DINPro",
    fontWeight: "500",
    lineHeight: "1.43",
    textDecoration: "none",
    color: "#fff",
  },
}))

const MobileNav = ({ location }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div className={classes.appLogoContainer}>
          <Link to="/">
            <img src={darkAppLogo} className={classes.appLogo} />
          </Link>
        </div>
      </div>
      <div className={classes.navContainer}>
        {renderNavLinks(location, classes)}
      </div>
    </div>
  )
}

const renderNavLinks = (location, classes) => {
  const { pathname } = location

  return (
    <>
      <nav className={classes.navGroup}>
        {Object.keys(mainNav).map((sectionKey, index) => {

          const section = mainNav[sectionKey]

          return section.items ? (
            <>
            <span className={classes.navItemHeader}>{section.heading}</span>
            <ul className={classes.navGroup}>
              {section.items.map((nav, index) => (
                <li key={index}>
                  <Link
                    to={nav.link}
                    className={classes.navText}
                    activeStyle={{ color: theme.palette.secondary.main }}
                  >
                    {nav.heading}
                  </Link>
                </li>
              ))}
            </ul>
            </>
          ) : (
            <li key={index}>
              <Link
                to={section.link}
                className={classes.navText}
                activeStyle={{ color: theme.palette.secondary.main }}
              >
                {section.heading}
              </Link>
            </li>
          )
        })}
      </nav>
    </>
  )
}

export default MobileNav
